import "./App.css";
import React from "react";
import device from "current-device";

import urlVaribles from "./urlVaribles";

import FunFactLogo from "./components/FunFactLogo";
import FunFactWaves from "./components/FunFactWaves";

function App() {
  const {
    appTitle,
    websiteUrl,
    iosStoreUrl,
    googleStoreUrl,
    appUrl,
  } = urlVaribles;

  const redirectUrls = React.useCallback(() => {
    if (iosStoreUrl?.length > 0 && device.ios()) {
      window.location = iosStoreUrl;
    } else if (googleStoreUrl?.length > 0 && device.andriod()) {
      window.location = googleStoreUrl;
    } else if (websiteUrl?.length > 0) {
      window.location = websiteUrl;
    }
  }, [googleStoreUrl, iosStoreUrl, websiteUrl]);

  React.useEffect(() => {
    redirectUrls();
  }, [redirectUrls]);

  return (
    <div className="App">
      <header className="App-header">
        <div className="content">
          {appTitle === "Fun Fact" && <FunFactLogo />}
          <p>{`Welcome to ${appTitle}! Please continue to one of these pages.`}</p>
          <div className="buttonWrapper">
            {appUrl?.length > 0 && (
              <a
                className="App-link"
                href={`${appUrl}${window.location.search}`}
                rel="noopener noreferrer"
              >
                {`${appTitle} App`}
              </a>
            )}
            {websiteUrl?.length > 0 && (
              <a
                className="App-link"
                href={`${websiteUrl}${window.location.search}`}
                rel="noopener noreferrer"
              >
                Website
              </a>
            )}
            {iosStoreUrl?.length > 0 && (
              <a className="App-link" href={iosStoreUrl} rel="noreferrer">
                Apple Store
              </a>
            )}
            {googleStoreUrl?.length > 0 && (
              <a className="App-link" href={googleStoreUrl} rel="noreferrer">
                Google Store
              </a>
            )}
          </div>
        </div>
        {appTitle === "Fun Fact" && <FunFactWaves />}
      </header>
    </div>
  );
}

export default App;
