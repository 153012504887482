const urlVaribles = {
  appTitle: "Fun Fact",
  websiteUrl: "https://funfactgame.com",
  appUrl: "funfact://",
  iosStoreUrl:
    "https://apps.apple.com/us/app/fun-fact-best-party-game-app/id1525612753",
  googleStoreUrl: "",
};

export default urlVaribles;
